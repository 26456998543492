import React from 'react';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyAB6F9vyLc7qWcoQ4O42UoNbv28_R6-Vrg",
    authDomain: "flipoversoft-188f8.firebaseapp.com",
    projectId: "flipoversoft-188f8",
    storageBucket: "flipoversoft-188f8.appspot.com",
    messagingSenderId: "327454564011",
    appId: "1:327454564011:web:1d7d3b504a7a49368c21ee",
    measurementId: "G-7C68Q4FTRE"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  logEvent(analytics, 'page_view', {
    page_location: 'https://flipoversoft.com', 
    page_path: '/', 
    page_title: 'Home'});

  return (
    <div className="container mx-auto">
        <h1 className="text-3xl">
          Flipover Software
        </h1>
        <p className="text-sm">Copyright 2023 Flipover Software LLC.</p>
    </div>
  );
}

export default App;
